<template>
    <div>
        <v-card class="mb-7 pa-3" v-for="group in groups" :key="group.id">
            <v-list
                subheader
                two-line
                >
                <h3 style='display: inline'>{{group.name}}

                </h3>
                                                                      <v-btn v-on:click.prevent="tempGroup=JSON.parse(JSON.stringify(group));departmentModal=true" icon color="primary"  class="ml-3"><v-icon >fa fa-cog</v-icon></v-btn>
                    <v-btn icon  color="error" @click.prevent="tempGroup=group; groupDeleteModal=true"><v-icon>fa fa-trash</v-icon></v-btn> 
                    <v-list-item-subtitle>{{group.address}}</v-list-item-subtitle>

                <v-divider inset></v-divider>
                <v-list-item-group color="primary">
         
                        <v-list-item  v-for="user in group.users" :key="user.id">
                            <v-list-item-avatar>
                                <v-icon  x-large v-if="!user.user.avatar"
                                         class="blue-grey"
                                         dark>mdi-account</v-icon>
                                <v-avatar x-large v-else min-width="40px" height="40px"><img :src="user.user.avatar"/></v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class=" py-1">{{user.user.last_name}} {{user.user.name}} {{user.user.second_name}}

                                </v-list-item-title>
                                
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click='tempUser=user; tempGroup=group; userDeleteModal=true'><v-icon color='error'>mdi-delete</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item> 
                        <v-list-item  @click="userNew.groupId=group.id;userNewModal=true">
                            <v-list-item-avatar>
                                <v-icon
                                    x-large
                                    class="blue"
                                    dark
                                    >mdi-plus</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content >
                                <v-list-item-title class=" py-2" >Добавить участника</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-dialog v-model="userNewModal" persistent max-width="540">
            <v-card>
                <v-card-title class="headline">Добавить участника</v-card-title>
                <v-card-text>    
                <userSearch :value.sync="userNew.userId"/>
                    </v-card-text>
                            <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="userNewModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="userNewSave()">Добавить</v-btn>
                </v-card-actions>
                </v-card>
        </v-dialog>
        <v-dialog v-model="userDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>Подтвердите удаление участника из группы.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="userDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="userDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
               <v-dialog v-model="departmentModal" persistent max-width="540">
            <v-card>
                <v-card-title class="headline"><span v-if="!tempGroup.id">Создать</span><span v-else>Изменить</span></v-card-title>
                <v-card-text><v-text-field v-model="tempGroup.name" label="Название"></v-text-field></v-card-text>
                <v-card-text><v-text-field v-model="tempGroup.address" label="Адрес"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="departmentModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="departmentSave()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
                <v-dialog v-model="groupDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>Подтвердите удаление группы.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="groupDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="groupDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn color="primary"  @click="tempGroup={};departmentModal=true"><v-icon class="mr-3">fa fa-plus</v-icon>Добавить</v-btn>
    </div>
</template>
<script>
import userSearch from './userSearch'
    export default {
        name: "index",
        data: () => ({
                groups: [],
                tempUser:{},
                tempGroup:{},
                userNew:{userId:null},
                userNewModal:false,
                userDeleteModal:false,
                groupDeleteModal:false,
                departmentModal:false
            }),
        components: {
            userSearch
        },
        methods: {
            departmentSave() {
                if(this.tempGroup.id){
                    this.$http.post('groups/update?id='+this.tempGroup.id,
                        this.tempGroup).then(response => {
                        var groupIndex= this.groups.findIndex(val => val.id == this.tempGroup.id);
                        this.groups[groupIndex].name=response.data.name
                        this.groups[groupIndex].address=response.data.address
                        this.departmentModal = false
                        this.tempGroup = {}
                    })          
                } else {
                    this.$http.post('groups/add?level=control',
                        this.tempGroup).then(response => {
                        this.groups.push(response.data)
                        this.departmentModal = false
                        this.tempGroup = {}
                    })
                }
            },     
            userDelete(){
                   this.$http.get('groups/user-delete?userId='+this.tempUser.user.user_id+'&groupId='+this.tempGroup.id).then(() => {
                    var userIndex= this.tempGroup.users.findIndex(val => val.user.user_id == this.tempUser.user.user_id);
                    this.tempGroup.users.splice(userIndex,1)
                    this.userDeleteModal=false
                })

            },
            groupDelete(){
                   this.$http.get('groups/delete?id='+this.tempGroup.id).then(() => {
                    var groupIndex= this.groups.findIndex(val => val.id == this.tempGroup.id);
                    this.groups.splice(groupIndex,1)
                    this.groupDeleteModal=false
                })

            },
            fetchData() {
                this.$http.get('groups/get-main-departments').then(response => {
                    this.groups = response.data.groups
                })
            },
            userNewSave(){
                this.$http.post('groups/user-add',
                        this.userNew).then(response => {
                        var group = this.groups.find(val => val.id == this.userNew.groupId);
                        if(!group.users) { group.users=[]}
                        group.users.push(response.data)
                        this.userNew = {}
                        this.userNewModal = false
                })
            }
                
            
            
        },
        mounted() {
            this.fetchData()
        }
    };
</script>