<template>

            <v-card>
                <v-card-title class="headline">Курсы</v-card-title>
                <v-card-text>
                    
                    <v-data-table
                        :headers="headers"
                        :items="courses"
                        sort-by="id"
                        class="elevation-1"
                        no-data-text="Результатов нет"
                no-results-text="Результатов нет"
                :footer-props="{'items-per-page-text':'Количество',
                'items-per-page-options':[5,10,20],
                
                }"
                        >
                                    <template v-slot:footer.page-text="{ pageStart,pageStop,itemsLength }">
               {{ pageStart}}-{{pageStop}} из {{itemsLength}}
            </template>
                        <template v-slot:item.course="{ item }">
                            {{item.course.name}}
                        </template>             
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon :to="'/timetable/'+item.id">
                            <v-icon
                                small
                                
                                >
                                mdi-book
                            </v-icon>
                                </v-btn>
                            <v-icon
                                small
                                @click="courseRemove(item.id)"
                                >
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            Нет элементов
                        </template>
                    </v-data-table>
                    <center><v-btn color="primary" @click='courseAdd()' class='mt-3'>Добавить</v-btn></center>
                </v-card-text>
            </v-card>

</template>
<script>

    export default {
        name: "index",
        props:{
          groupId:String  
        },
        data: () => ({
            courses:[],
                headers: [
                    {
                        text: 'Курс',
                        value: 'course',
                    },
                    {text: 'Удалить', value: 'actions', sortable: false},
                ],
            }),
            watch:{
                'groupId'(){
                    this.fetchData()
                }
            },
        components: {
            
        },
        methods: {
            fetchData() {
                this.$http.get('groups/courses',
                        {
                            params: {
                                groupId: this.groupId
                            }
                        }).then(response => {
                    this.courses = response.data
                })
            },
            
        },
        mounted() {
            this.fetchData()
        }
    };
</script>