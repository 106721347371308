<template>
    <div>
        <v-card class="mb-7 pa-3" v-for="group in groups" :key="group.id">
            <v-list
                subheader
                two-line
                >
                <span inset class="py-10">{{group.name}}
                </span>
                <v-divider inset></v-divider>
                <v-list-item-group color="primary">
         
                        <v-list-item  v-for="user in group.users" :key="user.id">
                            <v-list-item-avatar>
                                <v-icon  x-large v-if="!user.user.avatar"
                                         class="blue-grey"
                                         dark>mdi-account</v-icon>
                                <v-avatar x-large v-else min-width="40px" height="40px"><img :src="user.user.avatar"/></v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class=" py-1">{{user.user.name}} {{user.user.last_name}} {{user.user.second_name}}

                                </v-list-item-title>
                                
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click='tempUser=user; tempGroup=group; userDeleteModal=true'><v-icon color='error'>mdi-delete</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item> 
                        <v-list-item  @click="userNew.groupId=group.id;userNewModal=true">
                            <v-list-item-avatar>
                                <v-icon
                                    x-large
                                    class="blue"
                                    dark
                                    >mdi-plus</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content >
                                <v-list-item-title class=" py-2" >Добавить участника</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-dialog v-model="userNewModal" persistent max-width="540">
            <v-card>
                <v-card-title class="headline">Добавить участника</v-card-title>
                <v-card-text>    
                <userSearch :value.sync="userNew.userId"/>
                    </v-card-text>
                            <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="userNewModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="userNewSave()">Добавить</v-btn>
                </v-card-actions>
                </v-card>
        </v-dialog>
        <v-dialog v-model="userDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>Подтвердите удаление участника из группы.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="userDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="userDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import userSearch from './userSearch'
    export default {
        name: "index",
        data: () => ({
                groups: [],
                tempUser:{},
                tempGroup:{},
                userNew:{userId:null},
                userNewModal:false,
                userDeleteModal:false,
            }),
        components: {
            userSearch
        },
        methods: {
            userDelete(){
                   this.$http.get('groups/user-delete?userId='+this.tempUser.user.user_id+'&groupId='+this.tempGroup.id).then(() => {
                    var userIndex= this.tempGroup.users.findIndex(val => val.user.user_id == this.tempUser.user.user_id);
                    this.tempGroup.users.splice(userIndex,1)
                    this.userDeleteModal=false
                })

            },
            fetchData() {
                this.$http.get('groups/get-all').then(response => {
                    this.groups = response.data.groups
                })
            },
            userNewSave(){
                this.$http.post('groups/user-add',
                        this.userNew).then(response => {
                        var group = this.groups.find(val => val.id == this.userNew.groupId);
                        group.users.push(response.data)
                        this.userNew = {}
                        this.userNewModal = false
                })
            }
                
            
            
        },
        mounted() {
            this.fetchData()
        }
    };
</script>