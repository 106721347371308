var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Курсы")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.courses,"sort-by":"id","no-data-text":"Результатов нет","no-results-text":"Результатов нет","footer-props":{'items-per-page-text':'Количество',
    'items-per-page-options':[5,10,20],
    
    }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" из "+_vm._s(itemsLength)+" ")]}},{key:"item.course",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.course.name)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":'/timetable/'+item.id}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-book ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.courseRemove(item.id)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" Нет элементов ")]},proxy:true}])}),_c('center',[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.courseAdd()}}},[_vm._v("Добавить")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }