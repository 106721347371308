<template>
    <div>
        <v-card class="mb-7 pa-3" v-for="station in stations" :key="station.id">
            <v-list
                subheader
                two-line
                >
                <h3 style='display: inline'>{{station.name}}

                </h3>
                <v-btn v-on:click.prevent="tempGroup=JSON.parse(JSON.stringify(station));stationModal=true" icon color="primary"  class="ml-3"><v-icon >fa fa-cog</v-icon></v-btn>
                <v-btn icon  color="error" @click.prevent="tempGroup=station; groupDeleteModal=true"><v-icon>fa fa-trash</v-icon></v-btn> 
                <v-list-item-subtitle>{{station.address}}</v-list-item-subtitle>
                <v-divider inset></v-divider>
                <v-list-item-group color="primary">
                    <v-list-group v-for="guard in station.guards" :key="guard.id">
                        <template v-slot:activator>
                            <v-list-item  style="padding-left: 0px;" >
                                <v-list-item-avatar>
                                    <v-icon small
                                            class="blue-grey"
                                            dark >mdi-account-group</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content >
                                    <v-list-item-title class=" py-2">{{guard.name}}
                                        <v-btn icon  color="primary" small class="ml-3" v-on:click.prevent.stop="tempGroup=guard; coursesModal=true"><v-icon small>fa fa-book</v-icon></v-btn>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="guard.desc">{{station.desc}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item  v-for="user in guard.users" :key="user.id" style="zoom: 0.9; padding-left:60px;">
                            <v-list-item-avatar>
                                <v-icon  x-large v-if="!user.user.avatar"
                                         class="blue-grey"
                                         dark>mdi-account</v-icon>
                                <v-avatar x-large v-else min-width="40px" height="40px"><img :src="user.user.avatar"/></v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class=" py-1">{{user.user.last_name}} {{user.user.name}} {{user.user.second_name}} 

                                </v-list-item-title>
                            </v-list-item-content>
                                                <v-list-item-action>
                            <v-btn icon @click='tempUser=user; tempGroup=guard; userDeleteModal=true'><v-icon color='error'>mdi-delete</v-icon></v-btn>
                        </v-list-item-action>
                        </v-list-item> 
                        <v-list-item  style="zoom: 0.9; padding-left:60px;"  @click="tempGroup=guard;userNew.groupId=guard.id;userNewModal=true">
                            <v-list-item-avatar>
                                <v-icon
                                    x-large
                                    class="blue"
                                    dark
                                    >mdi-plus</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content >
                                <v-list-item-title class=" py-2" >Добавить участника</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                </v-list-item-group>
            </v-list>
        </v-card>
        <v-dialog v-model="userNewModal" persistent max-width="540">
            <v-card>
                <v-card-title class="headline">Добавить участника</v-card-title>
                <v-card-text>    
                    <userSearch :value.sync="userNew.userId"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="userNewModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="userNewSave()">Добавить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
                <v-dialog v-model="userDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>Подтвердите удаление участника из группы.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="userDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="userDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="stationModal" persistent max-width="540">
            <v-card>
                <v-card-title class="headline"><span v-if="!tempGroup.id">Создать</span><span v-else>Изменить</span></v-card-title>
                <v-card-text><v-autocomplete autofocus label="Гарнизоны"  :loading="garrisonLoading" :items="garrisonsAvailable" v-model="tempGroup.parentId"
                                             item-text="name" item-value="id"></v-autocomplete>
                </v-card-text>
                <v-card-text><v-text-field v-model="tempGroup.name" label="Название пожарной части"></v-text-field></v-card-text>
                <v-card-text><v-text-field v-model="tempGroup.address" label="Адрес"></v-text-field></v-card-text>
                <v-card-text><v-select
          v-model="tempGroup.type"
          :items="[{value:'PCH',text:'ПЧ'},{value:'SPSCH',text:'СПСЧ'}]"
          item-text="text"
          item-value="value"
          label="Тип части"
          persistent-hint
          single-line
        ></v-select></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="stationModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="stationSave()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn color="primary"  @click="stationModal=true" v-if="adminGroups.length>0"><v-icon class="mr-3">fa fa-plus</v-icon>Добавить пожарную часть</v-btn>
        <v-dialog v-model="coursesModal" max-width="800">
            <courses v-if='tempGroup' :groupId='tempGroup.id'/>
        </v-dialog>
        <v-dialog v-model="groupDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>Подтвердите удаление группы.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="groupDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="groupDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import courses from './courses'
    import userSearch from './userSearch'
    export default {
        name: "index",
        data: () => ({
                stations: [],
                stationNew: {},
                adminGroups: [],
                stationModal: false,
                userNew: {},
                users: [],
                                tempUser: {},

                userNewModal: false,
                usersLoading: false,
                userDeleteModal: false,
                garrisonsAvailable: [],
                garrisonLoading: false,
                search: null,
                groupDeleteModal: false,
                tempGroup: {},
                coursesModal: false
            }),
        components: {
            courses,
            userSearch
        },
        methods: {
            groupDelete() {
                this.$http.get('groups/delete?id=' + this.tempGroup.id).then(() => {
                    var groupIndex = this.stations.findIndex(val => val.id == this.tempGroup.id);
                    this.stations.splice(groupIndex, 1)
                    this.groupDeleteModal = false
                })

            },
            fetchData() {
                this.$http.get('stations/garrison-available').then(response => {

                    this.$set(this, 'garrisonsAvailable', response.data)


                    this.garrisonLoading = false
                })
                this.$http.get('stations/get-all',
                        {
                            params: {
                                id: this.$route.params.id
                            }
                        }).then(response => {
                    this.stations = response.data.stations
                    this.adminGroups = response.data.adminGroups
                })
            },
            stationSave() {
                if (this.tempGroup.id) {
                    this.$http.post('groups/update?id=' + this.tempGroup.id,
                            this.tempGroup).then(response => {
                        var groupIndex = this.stations.findIndex(val => val.id == this.tempGroup.id);
                        this.stations[groupIndex].name = response.data.name
                        this.stations[groupIndex].address = response.data.address
                        this.stations[groupIndex].type = response.data.type
                        this.stationModal = false
                        this.tempGroup = {}
                    })
                } else {
                    this.$http.post('stations/add',
                            this.tempGroup).then(response => {
                        this.stations.push(response.data)
                        this.stationModal = false
                        this.tempGroup = {}
                    })
                }
            },
            userDelete() {
                this.$http.get('groups/user-delete?userId=' + this.tempUser.user.user_id + '&groupId=' + this.tempGroup.id).then(() => {
                    var userIndex = this.tempGroup.users.findIndex(val => val.user.user_id == this.tempUser.user.user_id);
                    this.tempGroup.users.splice(userIndex, 1)
                    this.userDeleteModal = false
                })

            },
            userNewSave() {
                this.$http.post('groups/user-add',
                        this.userNew).then(response => {
                    this.tempGroup.users.push(response.data)
                    this.userNew = {}
                    this.userNewModal = false
                })
            }
        },
        mounted() {
            this.fetchData()
        },
        watch: {
            search() {
                // Items have already been loaded
                //if (this.permissionUsers.length > 0) return

                // Items have already been requested
                if (this.usersLoading)
                    return

                this.usersLoading = true
                this.users = []
                // Lazily load input items
                this.$http.get('user/user-search?q=' + this.search)
                        .then(res => {
                            this.$set(this, 'users', res.data)
                            // this.permissionUsers = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.usersLoading = false))
            },
        },
    };
</script>