<template>
    <div>
        <v-tabs
            background-color="primary"
            center-active
            dark
            v-model="tab"
            >
            <v-tab v-if="$auth.user().userGroups.find(val => val.group.level=='control' || val.group.level=='center')">Главные управления</v-tab>
            <v-tab v-if="$auth.user().userGroups.find(val => val.group.level=='garrison' || val.group.level=='control' || val.group.level=='center')">Гарнизоны</v-tab>
            <v-tab v-if="$auth.user().userGroups.find(val => val.group.level=='station' || val.group.level=='garrison' || val.group.level=='control' || val.group.level=='center' || (val.group.level=='guard' && val.group.admins==true))">Пожарные части</v-tab>
            <v-tab v-if="$auth.user().isAdmin">Другие</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-if="$auth.user().userGroups.find(val => val.group.level=='control' || val.group.level=='center')" >
                <v-card flat>
                    <v-card-text><mainDepartments/></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item  v-if="$auth.user().userGroups.find(val => val.group.level=='garrison' || val.group.level=='control' || val.group.level=='center')">
                <v-card flat>
                    <v-card-text><garrisons/></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="$auth.user().userGroups.find(val => val.group.level=='station' || val.group.level=='garrison' || val.group.level=='control' || val.group.level=='center' || (val.group.level=='guard' && val.group.admins==true))">
                <v-card flat>
                    <v-card-text><stations></stations></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="$auth.user().isAdmin">
                <v-card flat>
                    <v-card-text><other/></v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
    import mainDepartments from './mainDepartments'
    import garrisons from './garrisons'
    import stations from './stations'
    import other from './other'
    export default {
        name: "index",
        data: () => ({
                tab: 0,
                stations: [],
                stationNewModal: false
            }),
        components: {
            mainDepartments,
            garrisons,
            stations,
            other
        },
        methods: {
            fetchData() {
                this.$http.get('station/get-all',
                        {
                            params: {
                                id: this.$route.params.id
                            }
                        }).then(response => {
                    this.stations = response.data
                })

            },
        },
        mounted() {

        }
    };
</script>